/* Some global applied styles */
@import './variables.scss';

strong,
span {
  &.player-one {
    color: $playerOneColor;
  }

  &.player-two {
    color: $playerTwoColor;
  }
}
