@import '../../styles/variables.scss';

.title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;

  h1 {
    text-align: center;
    background-image: linear-gradient(
      45deg,
      $playerOneBorderColor,
      $playerOneColor,
      $playerTwoColor,
      $playerTwoBorderColor
    );
    font-size: 7vmin;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 2vmin;
    padding: 1vmin;
  }
}
