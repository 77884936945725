@import '../../styles/variables.scss';

.controls {
  position: relative;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  h4,
  h2 {
    margin-bottom: 0;
    margin-top: 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    width: 100%;
    border: none;
    padding: 10px;
    align: center;
  }

  #loadGame {
    color: white;
    border-radius: 2px;
    background: dodgerblue;
    box-shadow: 0 0 5px -1px rgba(199, 195, 195, 0.2);
    cursor: pointer;
    vertical-align: middle;
    padding: 5px;
    width: auto;
    height: inherit;
    text-align: center;
  }
}
