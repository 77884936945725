@import '../../styles/variables.scss';

.board-container {
  display: flex;
  padding-left: 5vmin;
  padding-right: 5vmin;

  @media (max-width: $screen-lg) {
    flex-direction: column;
  }

  .board {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    border: 0.4vmin solid $boardBorderColor;
    border-radius: 5vmin;
    position: relative;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);

    .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 0.3vmin solid $boardCircleColor;
      z-index: 3;
    }

    .line {
      position: absolute;
      z-index: 3;
      border: 0.15vmin solid $boardCircleColor;
      top: 50%;
      left: 50%;

      &.line-horizontal {
        width: 20vmin;
        height: 0;
      }

      &.line-vertical {
        height: 20vmin;
        width: 0;
      }

      &.line-left {
        transform: translate(calc(-50% - 20vmin), calc(-50%));
      }

      &.line-right {
        transform: translate(calc(-50% + 20vmin), calc(-50%));
      }

      &.line-top {
        transform: translate(calc(-50%), calc(-50% - 20vmin));
      }

      &.line-bottom {
        transform: translate(calc(-50%), calc(-50% + 20vmin));
      }
    }

    .grid {
      position: absolute;

      &.bottom {
        bottom: 0.5vmin;
        left: 0;
        width: 70vmin;

        div {
          width: 10vmin;
          display: inline-flex;
          justify-content: center;
        }
      }

      &.left {
        left: 0.5vmin;
        top: 0;
        height: 70vmin;

        .row {
          height: 10vmin;
          display: table-row;

          .col {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .board-controls {
    flex-grow: 1;
    margin-left: 5vmin;
    width: 30vmin;
    height: 70vmin;
    border: 0.4vmin solid $boardBorderColor;
    border-radius: 5vmin;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);

    @media (max-width: $screen-lg) {
      width: 100%;
      max-width: 70vmin;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5vmin;
      height: auto;
    }
  }
}
