@import '../../styles/variables.scss';

.history-container {
  margin-top: 10px;
  overflow: hidden;
  border: 0.2vmin solid $boardCircleColor;
  border-radius: 5vmin;
  flex-grow: 1;

  .history-inner {
    height: 100%;
    overflow-y: auto;
    padding: 2.5vmin;
    max-height: 50vmin;

    .history-entry {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid $boardCircleColor;

      &.inactive {
        opacity: 0.5;
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}
