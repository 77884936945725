.template {
  .background {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -999;
    display: flex;
    justify-content: center;
    place-content: center;
    align-items: center;
    overflow: hidden;
  }
}
