// Variables for the color of the board
$boardCircleColor: #d8d8d8;
$boardEmptyFieldColor: #08090a;
$boardBorderColor: #08090a;

// Player 1
$playerOneColor: #3c719a;
$playerOneBorderColor: #253e4d;

// Player 2
$playerTwoColor: #f76b20;
$playerTwoBorderColor: #c7470b;

// Variables for chip colors
$chipSelectedColor: #4fbd4f;
$chipSelectedBorderColor: #389138;

// Variables for media queries
$screen-xs: 320px; // Extra small devices (portrait phones)
$screen-sm: 480px; // Small devices (landscape phones)
$screen-md: 768px; // Medium devices (tablets)
$screen-lg: 992px; // Large devices (desktops)
$screen-xl: 1200px; // Extra large devices (large desktops)
