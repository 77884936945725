@import '../../styles/variables.scss';

.field-container {
  position: absolute;
  top: 50%;
  left: 50%;
  // transition:
  //   transform 0.4s ease-out,
  //   z-index 0.4s step-end;
  z-index: 5;

  &.clickable {
    cursor: pointer;
  }

  .field {
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &.player-one {
      background-color: $playerOneColor;
      border: 0.3vmin solid $playerOneBorderColor;
    }

    &.player-two {
      background-color: $playerTwoColor;
      border: 0.3vmin solid $playerTwoBorderColor;
    }

    &.empty {
      width: 1.5vmin;
      height: 1.5vmin;
      background-color: $boardEmptyFieldColor;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.show-chip-on-hover {
    &:hover {
      .field {
        width: 100%;
        height: 100%;
      }

      &.player-one-turn {
        .field {
          background-color: $playerOneColor;
          border: 0.3vmin solid $playerOneBorderColor;
        }
      }

      &.player-two-turn {
        .field {
          background-color: $playerTwoColor;
          border: 0.3vmin solid $playerTwoBorderColor;
        }
      }
    }
  }

  &.selected-to-move {
    .field {
      animation: selected-animation 2s infinite;
      -webkit-animation: selected-animation 2s infinite;
    }
  }
  @keyframes selected-animation {
    0%,
    49% {
      background-color: $chipSelectedColor;
      border: 0.3vmin solid $chipSelectedBorderColor;
    }
    50%,
    100% {
      background-color: transparent;
      border: 0.3vmin solid transparent;
    }
  }
  @-webkit-keyframes selected-animation {
    0%,
    49% {
      background-color: $chipSelectedColor;
      border: 0.3vmin solid $chipSelectedBorderColor;
    }
    50%,
    100% {
      background-color: transparent;
      border: 0.3vmin solid transparent;
    }
  }
}
